var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "400", persistent: "" },
      model: {
        value: this.$store.state.appointment.isZipRoEmailEntryVisible,
        callback: function ($$v) {
          _vm.$set(
            this.$store.state.appointment,
            "isZipRoEmailEntryVisible",
            $$v
          )
        },
        expression: "this.$store.state.appointment.isZipRoEmailEntryVisible",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "u-blue-text mb-0" }, [
            _c("p", { staticStyle: { width: "100%" } }, [
              _vm._v(" " + _vm._s(_vm.$t("SendZIPPEDRO")) + " "),
              _c("i", {
                staticClass: "mdi mdi-close-circle-outline float-right",
                on: { click: _vm.closeModal },
              }),
            ]),
          ]),
          _c(
            "v-card-text",
            [
              _c(
                "v-form",
                { ref: "sendZipForm" },
                [
                  _c(
                    "v-layout",
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "To",
                          clearable: "",
                          outlined: "",
                          dense: "",
                          placeholder: "Email address to send Zipped RO",
                          "persistent-hint": "",
                          rules: [
                            function (v) {
                              return (
                                /.+@.+\..+/.test(v) || "E-mail must be valid"
                              )
                            },
                          ],
                        },
                        model: {
                          value: _vm.emailAddress,
                          callback: function ($$v) {
                            _vm.emailAddress = $$v
                          },
                          expression: "emailAddress",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-layout",
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Subject",
                          outlined: "",
                          dense: "",
                          rules: [
                            function (v) {
                              return !!v || "Field is required"
                            },
                          ],
                        },
                        model: {
                          value: _vm.subject,
                          callback: function ($$v) {
                            _vm.subject = $$v
                          },
                          expression: "subject",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-layout",
                    [
                      _c("v-textarea", {
                        attrs: {
                          label: "Text",
                          outlined: "",
                          dense: "",
                          "persistent-hint": "",
                          rules: [
                            function (v) {
                              return !!v || "Field is required"
                            },
                          ],
                        },
                        model: {
                          value: _vm.body,
                          callback: function ($$v) {
                            _vm.body = $$v
                          },
                          expression: "body",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-layout",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-2",
                          attrs: {
                            text: "",
                            color: "primary",
                            loading:
                              this.$store.state.appointment.processingRequest,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.generateZipRo()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("Send")) + " ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-card-actions"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }