var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      staticClass: "w-100",
      attrs: { persistent: "" },
      model: {
        value: this.$store.state.appointment.isRepairOrderVisible,
        callback: function ($$v) {
          _vm.$set(this.$store.state.appointment, "isRepairOrderVisible", $$v)
        },
        expression: "this.$store.state.appointment.isRepairOrderVisible",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "u-blue-text mb-0" }, [
            _c("p", { staticStyle: { width: "100%" } }, [
              _vm._v(" " + _vm._s(_vm.$t("RepairOrderReport")) + " "),
              _c("i", {
                staticClass: "mdi mdi-close-circle-outline float-right",
                on: {
                  click: function ($event) {
                    return _vm.closeDialog()
                  },
                },
              }),
            ]),
          ]),
          _c(
            "v-card-text",
            [
              _c("v-layout", { staticClass: "w-100" }, [
                _c("iframe", {
                  staticClass: "iframe",
                  attrs: {
                    src: _vm.roUrl,
                    frameborder: "0",
                    height: "500px",
                    width: "100%",
                  },
                }),
              ]),
            ],
            1
          ),
          _c("v-card-actions"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }