var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-window",
        {
          model: {
            value: this.$store.state.appointment.editAppointmentStep,
            callback: function ($$v) {
              _vm.$set(
                this.$store.state.appointment,
                "editAppointmentStep",
                $$v
              )
            },
            expression: "this.$store.state.appointment.editAppointmentStep",
          },
        },
        [
          _c(
            "v-window-item",
            { attrs: { value: 1 } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "8", lg: "8", xl: "8" } },
                    [
                      _c("h3", { staticClass: "u-blue-text mb-0 mt-0" }, [
                        _vm._v(_vm._s(_vm.$t("Technician"))),
                      ]),
                      _c("p", { staticClass: "u-blue-text" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              this.$store.state.appointment
                                .myAppointmentSelected.technician
                            ) +
                            " "
                        ),
                      ]),
                      _c("p", { staticClass: "red--text mb-0" }, [
                        _vm._v(
                          _vm._s(_vm.$t("WantToChatOrCall")) +
                            " " +
                            _vm._s(
                              this.$store.state.appointment
                                .myAppointmentSelected.technician
                            ) +
                            ". " +
                            _vm._s(_vm.$t("TextOrCallByTappingOnTheSymbol")) +
                            " "
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "4", lg: "4", xl: "4" } },
                    [
                      _c("p", { staticClass: "u-green-text mb-0" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("phoneMask")(
                                this.$store.state.appointment
                                  .myAppointmentSelected.mechanicPhone
                              )
                            ) +
                            " "
                        ),
                      ]),
                      _c("v-layout", { staticClass: "mt-2" }, [
                        _c("img", {
                          staticClass: "mr-2",
                          attrs: {
                            src: require("../../assets/images/own_message.png"),
                            width: "32",
                            height: "32",
                            alt: "text message",
                          },
                          on: { click: _vm.openChatView },
                        }),
                        _c(
                          "a",
                          {
                            attrs: {
                              href:
                                "tel:" +
                                this.$store.state.appointment
                                  .myAppointmentSelected.mechanicPhone,
                              "aria-label": "call mechanic",
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("../../assets/images/call.webp"),
                                width: "32",
                                height: "32",
                                alt: "phone call",
                              },
                            }),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0",
                      attrs: { cols: "12", md: "7", lg: "7", xl: "7" },
                    },
                    [
                      _c(
                        "v-layout",
                        {
                          staticClass:
                            "u-blue-text subtitle-1 font-weight-bold",
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("AppointmentId")) + " ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0",
                      attrs: { cols: "12", md: "5", lg: "5", xl: "5" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "u-blue-text subtitle-1 text-sm-left text-md-right",
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                this.$store.state.appointment
                                  .myAppointmentSelected.id
                              )
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0",
                      attrs: { cols: "12", md: "6", lg: "6", xl: "7" },
                    },
                    [
                      _c(
                        "v-layout",
                        {
                          staticClass:
                            "u-blue-text subtitle-1 font-weight-bold",
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("Vehicle")) + " ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0",
                      attrs: { cols: "12", md: "6", lg: "6", xl: "5" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "u-blue-text subtitle-1 text-sm-left text-md-right",
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                this.$store.state.appointment
                                  .myAppointmentSelected.vehicle
                              )
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
              this.$store.state.appointment.myAppointmentSelected.engine
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-0 pb-0",
                          attrs: { cols: "12", md: "6", lg: "6", xl: "7" },
                        },
                        [
                          _c(
                            "v-layout",
                            {
                              staticClass:
                                "u-blue-text subtitle-1 font-weight-bold",
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("Engine")) + " ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-0 pb-0",
                          attrs: { cols: "12", md: "6", lg: "6", xl: "5" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "u-blue-text subtitle-1 text-sm-left text-md-right",
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    this.$store.state.appointment
                                      .myAppointmentSelected.engine
                                  )
                                ),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              this.$store.state.appointment.myAppointmentSelected.mileage !== 0
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-0 pb-0",
                          attrs: { cols: "12", md: "6", lg: "6", xl: "7" },
                        },
                        [
                          _c(
                            "v-layout",
                            {
                              staticClass:
                                "u-blue-text subtitle-1 font-weight-bold",
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("Mileage")) + " ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-0 pb-0",
                          attrs: { cols: "12", md: "6", lg: "6", xl: "5" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "u-blue-text subtitle-1 text-sm-left text-md-right",
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    this.$store.state.appointment
                                      .myAppointmentSelected.mileage
                                  )
                                ),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0",
                      attrs: { cols: "12", md: "6", lg: "6", xl: "7" },
                    },
                    [
                      _c(
                        "v-layout",
                        {
                          staticClass:
                            "u-blue-text subtitle-1 font-weight-bold",
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("Date")) + " ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0",
                      attrs: { cols: "12", md: "6", lg: "6", xl: "5" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "u-blue-text subtitle-1 text-sm-left text-md-right",
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                this.$store.state.appointment
                                  .myAppointmentSelected.date
                              )
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0",
                      attrs: { cols: "12", md: "5", lg: "5", xl: "5" },
                    },
                    [
                      _vm.visibleIfTowing()
                        ? _c(
                            "v-layout",
                            {
                              staticClass:
                                "u-blue-text subtitle-1 font-weight-bold",
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("PickUp")) + " ")]
                          )
                        : _vm._e(),
                      !_vm.visibleIfTowing()
                        ? _c(
                            "v-layout",
                            {
                              staticClass:
                                "u-blue-text subtitle-1 font-weight-bold",
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("Location")) + " ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0",
                      attrs: { cols: "12", md: "7", lg: "7", xl: "7" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "u-blue-text subtitle-1 text-sm-left text-md-right",
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                this.$store.state.appointment
                                  .myAppointmentSelected.address
                              ) + " "
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
              this.$store.state.appointment.myAppointmentSelected.comments !==
              ""
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-0 pb-0",
                          attrs: { cols: "12", md: "5", lg: "5", xl: "5" },
                        },
                        [
                          _c(
                            "v-layout",
                            {
                              staticClass:
                                "u-blue-text subtitle-1 font-weight-bold",
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("Comments")) + " ")]
                          ),
                        ],
                        1
                      ),
                      _c("v-col", {
                        staticClass: "pt-0 pb-0",
                        attrs: { cols: "12", md: "7", lg: "7", xl: "7" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              this.$store.state.appointment.myAppointmentSelected.comments !==
              ""
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-0 pb-0 mb-3",
                          attrs: { cols: "12" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "u-blue-text subtitle-1 text-sm-left",
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    this.$store.state.appointment
                                      .myAppointmentSelected.comments
                                  ) + " "
                                ),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              this.$store.state.appointment.myAppointmentSelected.photos
                .length > 0
                ? _c(
                    "v-expansion-panels",
                    { staticClass: "my-4" },
                    [
                      _c(
                        "v-expansion-panel",
                        {
                          staticStyle: {
                            "border-left": "none !important",
                            "border-right": "none !important",
                          },
                        },
                        [
                          _c(
                            "v-expansion-panel-header",
                            {
                              staticClass:
                                "pa-0 u-blue-text subtitle-1 font-weight-bold",
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("Photos")) + " ")]
                          ),
                          _c(
                            "v-expansion-panel-content",
                            [
                              _c(
                                "v-carousel",
                                {
                                  attrs: {
                                    height: "300px",
                                    "hide-delimiter-background": "",
                                  },
                                },
                                _vm._l(
                                  this.$store.state.appointment
                                    .myAppointmentSelected.photos,
                                  function (item, i) {
                                    return _c("v-carousel-item", {
                                      key: i,
                                      attrs: {
                                        src: item,
                                        "reverse-transition": "fade-transition",
                                        transition: "fade-transition",
                                        height: "100%",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.openWindows(item)
                                        },
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.visibleIfTowing()
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-0 pb-0",
                          attrs: { cols: "12", md: "5", lg: "5", xl: "5" },
                        },
                        [
                          _c(
                            "v-layout",
                            {
                              staticClass:
                                "u-blue-text subtitle-1 font-weight-bold",
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("DropOff")) + " ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-0 pb-0",
                          attrs: { cols: "12", md: "7", lg: "7", xl: "7" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "u-blue-text subtitle-1 text-sm-left text-md-right",
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    this.$store.state.appointment
                                      .myAppointmentSelected.dropOff
                                  ) + " "
                                ),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0",
                      attrs: { cols: "12", md: "7", lg: "7", xl: "7" },
                    },
                    [
                      _c(
                        "v-layout",
                        {
                          staticClass:
                            "u-green-text subtitle-1 font-weight-bold\n                   text-md-right",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                this.$store.state.appointment
                                  .myAppointmentSelected.type
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._l(
                this.$store.state.appointment.myAppointmentSelected
                  .subCategoryByVendor,
                function (item) {
                  return _c(
                    "v-row",
                    { key: item.description, staticClass: "pt-0 pb-0" },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-0 pb-0",
                          staticStyle: { "max-width": "32px" },
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.isNeedForYourApproval(),
                                  expression: "isNeedForYourApproval()",
                                },
                              ],
                              staticClass: "u-blue-text",
                              on: {
                                click: function ($event) {
                                  return _vm.removeLineSubcategoryByVendor(item)
                                },
                              },
                            },
                            [_vm._v(" mdi-close ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-0 pb-0",
                          attrs: { cols: "10", md: "6", lg: "7", xl: "8" },
                        },
                        [
                          _c(
                            "v-layout",
                            {
                              staticClass:
                                "u-blue-text subtitle-1 font-weight-bold\n                   text-md-left",
                            },
                            [_vm._v(" " + _vm._s(item.description) + " ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-0 pb-0",
                          attrs: { cols: "12", md: "5", lg: "4", xl: "3" },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "u-blue-text subtitle-1\n                   text-md-right",
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm._f("currency")(item.price))
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                }
              ),
              _vm._l(
                this.$store.state.appointment.myAppointmentSelected
                  .subcategoriesModified,
                function (item) {
                  return _c(
                    "v-row",
                    { key: item.id, staticClass: "pt-0 pb-0" },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-0 pb-0",
                          attrs: { cols: "12", md: "7", lg: "8", xl: "9" },
                        },
                        [
                          _c(
                            "v-layout",
                            {
                              staticClass:
                                "u-blue-text subtitle-1 font-weight-bold\n                   text-md-right",
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(item.serviceSubCategory.description) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-0 pb-0",
                          attrs: { cols: "12", md: "5", lg: "4", xl: "3" },
                        },
                        [
                          _c("div", [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "u-blue-text subtitle-1\n                   text-md-right",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("currency")(
                                        item.serviceSubCategory.price
                                      )
                                    ) +
                                    " "
                                ),
                                _vm.subCatsEditable()
                                  ? _c("i", {
                                      staticClass: "mdi mdi-close icon-remove",
                                      staticStyle: { cursor: "pointer" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeSubcategory(item.id)
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  )
                }
              ),
              _c(
                "v-row",
                [
                  this.appointment.serviceCategoryId == 4
                    ? _c(
                        "v-col",
                        {
                          staticClass: "pt-0 pb-0",
                          attrs: { cols: "12", md: "5", lg: "5", xl: "5" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "u-blue-text subtitle-1 font-weight-bold text-right",
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("currency")(this.appointment.total)
                                  ) + " "
                                ),
                              ]),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0",
                      attrs: { cols: "7", md: "7", lg: "7", xl: "7" },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "u-blue-text subtitle-1 font-weight-bold",
                        },
                        [_vm._v(_vm._s(_vm.$t("TotalLabor")))]
                      ),
                    ]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0",
                      attrs: { cols: "5", md: "5", lg: "5", xl: "5" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "u-blue-text subtitle-1 text-right" },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm._f("currency")(
                                  this.$store.state.appointment
                                    .myAppointmentSelected.sumOfLabor
                                )
                              ) + " "
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0",
                      attrs: { cols: "7", md: "7", lg: "7", xl: "7" },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "u-blue-text subtitle-1 font-weight-bold",
                        },
                        [_vm._v(_vm._s(_vm.$t("TotalParts")))]
                      ),
                    ]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0",
                      attrs: { cols: "5", md: "5", lg: "5", xl: "5" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "u-blue-text subtitle-1 text-right" },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm._f("currency")(
                                  this.$store.state.appointment
                                    .myAppointmentSelected.sumOfParts
                                )
                              ) + " "
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0",
                      attrs: { cols: "7", md: "7", lg: "7", xl: "7" },
                    },
                    [
                      _c(
                        "v-layout",
                        {
                          staticClass:
                            "u-blue-text subtitle-1 font-weight-bold",
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("TotalCharges")) + " ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0",
                      attrs: { cols: "5", md: "5", lg: "5", xl: "5" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "u-blue-text subtitle-1 text-right" },
                        [
                          _c("span", [
                            _vm._v(" " + _vm._s(_vm._f("currency")(_vm.total))),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0",
                      attrs: { cols: "7", md: "7", lg: "7", xl: "7" },
                    },
                    [
                      _c(
                        "v-layout",
                        {
                          staticClass:
                            "u-blue-text subtitle-1 font-weight-bold",
                        },
                        [_vm._v(_vm._s(_vm.$t("Taxes")))]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0",
                      attrs: { cols: "5", md: "5", lg: "5", xl: "5" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "u-blue-text subtitle-1 text-right" },
                        [
                          _c("span", [
                            _vm._v(
                              " " + _vm._s(_vm._f("currency")(_vm.taxes)) + " "
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0",
                      attrs: { cols: "7", md: "7", lg: "7", xl: "7" },
                    },
                    [
                      _c(
                        "v-layout",
                        {
                          staticClass:
                            "u-blue-text subtitle-1 font-weight-bold",
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("Discount")) + " ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0",
                      attrs: { cols: "5", md: "5", lg: "5", xl: "5" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "u-blue-text subtitle-1 text-right text-right",
                        },
                        [
                          _c("span", { staticClass: "red--text" }, [
                            _vm._v(
                              " (" +
                                _vm._s(_vm._f("currency")(_vm.discount)) +
                                ") "
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0",
                      attrs: { cols: "7", md: "7", lg: "7", xl: "7" },
                    },
                    [
                      _c(
                        "v-layout",
                        {
                          staticClass:
                            "u-blue-text subtitle-1 font-weight-bold",
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("NetAmountDue")) + " ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0",
                      attrs: { cols: "5", md: "5", lg: "5", xl: "5" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "u-blue-text subtitle-1 text-right" },
                        [
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(_vm._f("currency")(_vm.netAmmountDue()))
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c("v-layout", { staticClass: "mt-2" }, [
                _c(
                  "span",
                  {
                    staticClass:
                      "u-blue-text subtitle-1 font-weight-bold mx-auto",
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("Status")) +
                        ": " +
                        _vm._s(this.getStatusText()) +
                        " "
                    ),
                  ]
                ),
              ]),
              ["Needs your Approval", "Needs you enter a new card"].includes(
                this.$store.state.appointment.myAppointmentSelected.status
              )
                ? _c(
                    "v-row",
                    { staticClass: "mt-2" },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: " mr-1",
                          attrs: { color: "green ", outlined: "" },
                          on: { click: _vm.decline },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("Decline")) + " ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "u-background-primary white--text",
                            loading: _vm.confirmCancelAppintmentLoader,
                            disabled: _vm.total <= 0,
                          },
                          on: { click: _vm.approveView },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("Approve")) + " ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              this.$store.state.appointment.myAppointmentSelected.status ===
              "Waiting for Estimate"
                ? _c(
                    "v-row",
                    { staticClass: "mt-2" },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: " mr-1",
                          attrs: {
                            color: "green ",
                            outlined: "",
                            loading: _vm.confirmCancelAppintmentLoader,
                          },
                          on: { click: _vm.confirmCancelAppointment },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("CancelAppointment")) + " "
                          ),
                        ]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "u-background-primary white--text" },
                          on: { click: _vm.updateSubcategoriesAppointment },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("Ok")) + " ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            2
          ),
          _c(
            "v-window-item",
            { attrs: { value: 2 } },
            [_c("confirm-appointment")],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "400" },
          model: {
            value: _vm.smsDialogVisible,
            callback: function ($$v) {
              _vm.smsDialogVisible = $$v
            },
            expression: "smsDialogVisible",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "u-blue-text" }, [
                _c("p", { staticStyle: { width: "100%" } }, [
                  _c("i", { staticClass: "mdi mdi-forum" }),
                  _vm._v(" " + _vm._s(_vm.$t("SendMessage")) + " "),
                  _c("i", {
                    staticClass: "mdi mdi-close-circle-outline float-right",
                    on: { click: _vm.closeSendMessageDialog },
                  }),
                ]),
              ]),
              _c(
                "v-card-text",
                [
                  _c("sms-sender", {
                    attrs: {
                      mechanicName: _vm.mechanicName,
                      tupletId: _vm.tupletId,
                      userId: _vm.userId,
                      messages: _vm.messagesList,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "350", persistent: "" },
          model: {
            value: _vm.cancelDeleteDialog,
            callback: function ($$v) {
              _vm.cancelDeleteDialog = $$v
            },
            expression: "cancelDeleteDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "u-blue-text mb-0" }, [
                _c("p", { staticStyle: { width: "100%" } }, [
                  _vm._v(" " + _vm._s(_vm.$t("Confirmation")) + " "),
                  _c("i", {
                    staticClass: "mdi mdi-close-circle-outline float-right",
                    on: {
                      click: function ($event) {
                        _vm.cancelDeleteDialog = false
                      },
                    },
                  }),
                ]),
              ]),
              _c("v-card-text", [
                _c("span", { staticClass: "u-blue-text sub-title-1" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("AreYouSureYouWantToDeleteThisItem")) +
                      " "
                  ),
                ]),
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "green", outlined: "" },
                      on: {
                        click: function ($event) {
                          _vm.cancelDeleteDialog = false
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("No")) + " ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "white--text u-background-primary",
                      on: { click: _vm.removeItemOk },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("Yes")) + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "350", persistent: "" },
          model: {
            value: _vm.cancelDeclineDialog,
            callback: function ($$v) {
              _vm.cancelDeclineDialog = $$v
            },
            expression: "cancelDeclineDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "u-blue-text mb-0" }, [
                _c(
                  "p",
                  {
                    staticStyle: {
                      width: "100%",
                      "font-family": "rubik",
                      "font-weight": "600",
                      "margin-bottom": "5px",
                    },
                  },
                  [
                    _vm._v(" " + _vm._s(_vm.$t("Confirmation")) + " "),
                    _c("i", {
                      staticClass: "mdi mdi-close-circle-outline float-right",
                      on: {
                        click: function ($event) {
                          _vm.cancelDeclineDialog = false
                        },
                      },
                    }),
                  ]
                ),
              ]),
              _c("v-card-text", [
                _c(
                  "span",
                  {
                    staticClass: "u-blue-text sub-title-1",
                    staticStyle: {
                      "font-size": "17px",
                      "lin-height": "0.95",
                      "letter-spacing": "0.7px",
                      "font-family": "rubik",
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("AreYouSureYouWantToCancelThisAppointment")
                        ) +
                        " " +
                        _vm._s(this.declineMessageWithFee) +
                        " "
                    ),
                  ]
                ),
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "green", outlined: "" },
                      on: {
                        click: function ($event) {
                          _vm.cancelDeclineDialog = false
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("NoDoNotCancel")) + " ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "white--text u-background-primary",
                      attrs: { loading: _vm.confirmCancelAppintmentLoader },
                      on: { click: _vm.confirmCancelAppointment },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("YesCancel")) + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }